import React, { useEffect, useState } from 'react';
import Get_datos2 from '../API/datos2';
import { useNavigate } from 'react-router-dom';

function FeaturesComponentes({toggleDeseo, toggleComparar}) {
    const [productos, setProductos] = useState([]);
    const [elementos_inicio, setProductos_inicio] = useState([]);
    const navegador = useNavigate();

    const Datos_ramdon = async () => {
        try {
            const response = await fetch('https://compufinder.net/php/ramdon2.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const responseBody = await response.text();
            
            if (!response.ok) {
                throw new Error("Error " + response.status + " al llamar al API: " + response.statusText);
            }
            
            let data;
            try {
                data = JSON.parse(responseBody);
                console.log(data);
            } catch (error) {
                throw new Error("Error al analizar el JSON: " + error.message);
            }

            setProductos(data);
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    useEffect(() => {
        Datos_ramdon();
    }, []);

    const obtenerPrimeraImagen = (ruta_imagen) => {
        try {
            const imagenesString = ruta_imagen
                .replace(/&quot;/g, '"')
                .replace(/\\+/g, '')
                .replace(/(^"|"$)/g, '');
            const imagenesArray = JSON.parse(imagenesString);
            return imagenesArray[0];
        } catch (error) {
            console.error("Error al parsear ruta_imagen:", error);
            return 'default_image.jpg';
        }
    };

    const click_elemento = async function (titulo) {
        const datos = await Get_datos2(titulo);
        if (datos) { 
            console.log(titulo);
            localStorage.setItem("elementos_inicio", JSON.stringify(datos));
            setProductos_inicio(datos);
            navegador(`/Shop_inicio`);
        } else {
            console.error("No se recibieron datos.");
        }
    };
    
    const CompararClick = (producto) => {
        toggleComparar(producto); 
        navegador('/comparacion'); 
    };

    const toggleDeseoCLick = (producto) => {
        toggleDeseo(producto);
        navegador('/Lista'); 
    };

    return (
        <>
            <section className="bg-light">
                <div className="container py-5">
                    <div className="row text-center py-3">
                        <div className="col-lg-6 m-auto">
                            <h1 className="h1">Productos Destacados</h1>
                            <p>
                                Explora nuestra selección de productos exclusivos. Haz clic en cada artículo para descubrir más detalles y características.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        {productos.map((producto, index) => {
                            const primeraImagen = obtenerPrimeraImagen(producto.ruta_imagen);

                            return (
                                <>
                                    <div className="col-md-4" key={index}>
                                        <div className="card mb-4 product-wap rounded-0">
                                            <div className="card rounded-0">
                                                <img className="card-img rounded-0 img-fluid" style={{ height: '250px', objectFit: 'cover' }} src={primeraImagen} alt={producto.titulo}  onClick={() => click_elemento(producto.titulo)} />
                                                <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <a className="btn btn-success text-white"  onClick={(e) => { e.preventDefault(); toggleDeseoCLick(producto, primeraImagen); }}>
                                                                <i className="fa-solid fa-cart-shopping" style={{backgroundColor:'transparent'}}></i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="btn btn-success text-white" onClick={(e) => { e.preventDefault(); CompararClick(producto)}}>
                                                                <i className="fas fa-balance-scale" style={{backgroundColor:'transparent'}}></i>
                                                            </a>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <a className="h3 text-decoration-none text-dark" onClick={() => click_elemento(producto.titulo)} style={{cursor:'pointer'}}>
                                                    {producto.titulo}
                                                </a>
                                                <p className="text-center mb-0" style={{ color: 'white' }}>${producto.precio_producto}</p>
                                                <span style={{ color: 'white' }}> {producto.origen.toUpperCase()}</span>
                                            </div>
                                        </div>
                                    </div>
                                
                                </>


                            );
                        })}
                    </div>
                </div>
            </section>
        
        </>
    );
}

export default FeaturesComponentes;