import { useState, useEffect } from "react";
import Producto_solo from '../components/producto_solo';
import Productos_similares from '../components/productos_similares';

export default function Text10({ elementos = [], toggleDeseo }) {
    const [productos, setProductos] = useState(() => {
        const productosGuardados = localStorage.getItem('productos');
        return productosGuardados ? JSON.parse(productosGuardados) : [];
    });

    useEffect(() => {
        if (elementos.length > 0) {
          
            localStorage.setItem('productos', JSON.stringify(elementos));
            setProductos(elementos);
        } else if (productos.length === 0) {
           
            const productosGuardados = localStorage.getItem('productos');
            if (productosGuardados) {
                setProductos(JSON.parse(productosGuardados));
            }
        }
    }, [elementos]);

    return (
       <> 
            {productos.length > 0 ? (
              <Producto_solo elementos={productos} toggleDeseo={toggleDeseo}  />
            ) : (
              <p>No product selected.</p>
            )}
            <section className="py-5">
                <div className="container">
                    <div className="row text-left p-2 pb-3"><h4>Related Products</h4></div>
                    <div id="carousel-related-product" className="slick-initialized slick-slider slick-dotted">
                        <div className="slick-list draggable">
                            <div className="slick-track">
                                <Productos_similares />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       </>
    );
}
