import Nuestra_hsitoria from "../components/nuestras_historia";
import Herramientas from "../components/herramientas";
import Marcas from "../components/marcas";
export default function Text() {
    return (
        <>
            <Nuestra_hsitoria />
            <Herramientas />
            <Marcas />
        </>
    );
}