//App.js
import Header from "./components/header";
import Modal from "./components/modal";
import Inicio from "./content/text1";
import Comparador from "./content/compara";
import Text2 from "./content/text2";
import Text3 from "./content/text3";
import Text5 from "./content/text5";
import Text6 from "./content/text6";
import Head from './content/text7';
import Text9 from "./content/text9";
import Text10 from "./content/text10";
import Contacto_html from './content/text8';
import XD from './content/xd.jsx'
import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes }  from "react-router-dom";

function App() {

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);

  const [elementos, setElementos] = useState(() => {
    const savedElementos = localStorage.getItem("elementos");
    return savedElementos ? JSON.parse(savedElementos) : [];
  });
  useEffect(() => {
    localStorage.setItem("elementos", JSON.stringify(elementos));
  }, [elementos]);

  const [productos_inicio, setProductos_inicio] = useState([]) 

  useEffect(() => {
    localStorage.setItem("productos_inicio", JSON.stringify(productos_inicio));
  }, [productos_inicio]);
  
  useEffect(() => {
    const savedProductosInicio = localStorage.getItem("productos_inicio");
    if (savedProductosInicio) {
      setProductos_inicio(JSON.parse(savedProductosInicio));
    }
  }, []);

  const [listaDeseos, setListaDeseos] = useState(() => {
    const savedListaDeseos = localStorage.getItem("listaDeseos");
    return savedListaDeseos ? JSON.parse(savedListaDeseos) : [];
  });

  useEffect(() => {
    localStorage.setItem("listaDeseos", JSON.stringify(listaDeseos));
  }, [listaDeseos]);

  const toggleDeseo = (producto) => {
    setListaDeseos((prevListaDeseos) => {
      if (prevListaDeseos.some((item) => item.id === producto.id)) {
        return prevListaDeseos.filter((item) => item.id !== producto.id);
      } else {
        const nuevoProducto = {
          id: producto.id,
          titulo: producto.titulo,
          precio: producto.precio_producto, 
          origen: producto.origen,
          url: producto.url_producto,
          imagenes: producto.ruta_imagen,
        };
        return [...prevListaDeseos, nuevoProducto];
      }
    });
  };

  const [productosComparar, setProductosComparar] = useState(() => {
    const savedProductosComparar = localStorage.getItem("productosComparar");
    return savedProductosComparar ? JSON.parse(savedProductosComparar) : [];
  });
    
  useEffect(() => {
    localStorage.setItem("productosComparar", JSON.stringify(productosComparar));
  }, [productosComparar]);

  const toggleComparar = (producto) => {
    setProductosComparar((prevProductosComparar) => {
      if (prevProductosComparar.some((item) => item.id === producto.id)) {
        return prevProductosComparar.filter((item) => item.id !== producto.id);
      } else {
        return [...prevProductosComparar, producto];
      }
    });
  };

  const vaciarComparar = () => {
    setProductosComparar([]);
  };


  return (
    <>
      <Head />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
      <Modal />
      <Router>
        <Header setElementos={setElementos} setCategoriaSeleccionada={setCategoriaSeleccionada} />
        <Routes>
          <Route path="/" 
            element={<Inicio toggleDeseo={toggleDeseo} 
            toggleComparar={toggleComparar} />} 
          />
          <Route path="/Inicio" element={<Inicio toggleDeseo={toggleDeseo} toggleComparar={toggleComparar} />} />
          <Route path="/Sobre" element={<Text2 />} />
          <Route path="/Comprar" element={<Text3 setElementos={setElementos} categoriaSeleccionada={categoriaSeleccionada} toggleDeseo={toggleDeseo} toggleComparar={toggleComparar}  />} />
          <Route path="/paginas/contacto" element={<Contacto_html />} />
          <Route path="/Shop" element={<Text5 elementos={elementos} categoriaSeleccionada={categoriaSeleccionada} toggleDeseo={toggleDeseo} />} />

          <Route path="/Shop_inicio" element={<Text10  toggleDeseo={toggleDeseo} />} />
          <Route path="/Lista" element={<Text9 listaDeseos={listaDeseos} toggleDeseo={toggleDeseo} />} />
          <Route path="/Shop_busqueda" element={<Text6 elementos= {elementos} toggleDeseo={toggleDeseo}  />} />
          <Route path="/xd" element={<XD /> } />

          <Route path="/Comparacion" element={ <Comparador  productos={productosComparar} toggleComparar={toggleComparar} vaciarComparar={vaciarComparar}  /> } /> 
        </Routes>
      </Router>
    </>
  );
}

export default App;

