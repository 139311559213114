
import Baner from "../components/baner"
import Categorias from "../components/categorias"
import Features_componentes from "../components/features_componentes"
export default function Inicio({toggleDeseo, toggleComparar}){
    return(
        <>
            <Baner />
            <Categorias />
            <Features_componentes toggleDeseo={toggleDeseo} toggleComparar={toggleComparar} />
        </>
    )
}