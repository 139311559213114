import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa el CSS de AOS
import headset from '../img/headset.png';
import laptops from '../img/laptops.png';
import grafica from '../img/tarjetas.png';
import accesorios from '../img/accesorios (1).png';
import { useEffect } from 'react';

function Categorias() {
    useEffect(() => {
        AOS.init(); // Inicializa AOS
    }, []);

    return (
        <section className="container py-5">
            <div className="row text-center pt-3" data-aos="fade" data-aos-duration="1500" data-aos-easing="ease-in-out">
                <div className="col-lg-6 m-auto">
                    <h1 className="h1">Explora Nuestras Categorías</h1>
                    <p>
                        En nuestra tienda en línea, ofrecemos una amplia variedad de componentes informáticos para satisfacer todas tus necesidades. ¡Descubre nuestras categorías destacadas!
                    </p>
                </div>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-12 col-md-4 p-6 mt-3" data-aos="fade" data-aos-duration="1500" data-aos-delay="100" data-aos-easing="ease-in-out">
                    <a>
                        <img src={laptops} className="rounded-circle img-fluid border" alt="Equipos Informáticos" />
                    </a>
                    <h5 className="text-center mt-3 mb-3">Equipos Informáticos</h5>
                    <p className="text-center">
                        Encuentra los mejores equipos informáticos, desde laptops hasta desktops, ideales para trabajo, juegos y más.
                    </p>
                </div>
                <div className="col-12 col-md-4 p-6 mt-3" data-aos="fade" data-aos-duration="1500" data-aos-delay="200" data-aos-easing="ease-in-out">
                    <a>
                        <img src={headset} className="rounded-circle img-fluid border" alt="Periféricos" />
                    </a>
                    <h5 className="text-center mt-3 mb-3">Periféricos</h5>
                    <p className="text-center">
                        Mejora tu experiencia con nuestros periféricos de alta calidad, incluyendo auriculares, teclados y ratones.
                    </p>
                </div>
            </div>

            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-12 col-md-4 p-6 mt-3" data-aos="fade" data-aos-duration="1500" data-aos-delay="300" data-aos-easing="ease-in-out">
                    <a>
                        <img src={grafica} className="rounded-circle img-fluid border" alt="Componentes Internos" />
                    </a>
                    <h5 className="text-center mt-3 mb-3">Componentes Internos</h5>
                    <p className="text-center">
                        Descubre una amplia gama de componentes internos, como tarjetas gráficas y placas base, para personalizar tu PC.
                    </p>
                </div>
                <div className="col-12 col-md-4 p-6 mt-3" data-aos="fade" data-aos-duration="1500" data-aos-delay="400" data-aos-easing="ease-in-out">
                    <a>
                        <img src={accesorios} className="rounded-circle img-fluid border" alt="Accesorios" />
                    </a>
                    <h5 className="text-center mt-3 mb-3">Accesorios</h5>
                    <p className="text-center">
                        Encuentra una variedad de accesorios útiles para complementar tus dispositivos, desde fundas hasta soportes y más.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Categorias;