import { useEffect } from 'react';

const RedirectToHtml = () => {
  useEffect(() => {
    window.location.href = "/contacto.html"; 
  }, []);

  return null; 
};

export default RedirectToHtml;
