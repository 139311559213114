function Footer() {
    return (
        <footer className="bg-dark" id="tempaltemo_footer">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pt-5">
                        <h2 className="h2 text-success border-bottom pb-3 border-light logo">Compufinder</h2>
                        <ul className="list-unstyled text-light footer-link-list">
                            <li>
                                <i className="fas fa-map-marker-alt fa-fw"></i>
                                BULEVAR VENEZUELA, COLONIA ROMA, SAN SALVADOR, EL SALVADOR, 503 San Salvador
                            </li>
                            <li>
                                <i className="fa fa-envelope fa-fw"></i>
                                <a className="text-decoration-none" href="mailto:compufinderr@gmail.com">compufinderr@gmail.com</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-4 pt-5">
                        <h2 className="h2 text-light border-bottom pb-3 border-light">Categorias</h2>
                        <ul className="list-unstyled text-light footer-link-list">
                            <li><a className="text-decoration-none" style={{ textDecoration: 'none', color: 'inherit', pointerEvents: 'none' }}>Periféricos</a></li>
                            <li><a className="text-decoration-none" style={{ textDecoration: 'none', color: 'inherit', pointerEvents: 'none' }}>Componentes internos</a></li>
                            <li><a className="text-decoration-none" style={{ textDecoration: 'none', color: 'inherit', pointerEvents: 'none' }}>Equipos informáticos</a></li>
                            <li><a className="text-decoration-none" style={{ textDecoration: 'none', color: 'inherit', pointerEvents: 'none' }}>Accesorios</a></li>
                        </ul>
                    </div>

                    <div className="col-md-4 pt-5">
                        <h2 className="h2 text-light border-bottom pb-3 border-light">Información</h2>
                        <ul className="list-unstyled text-light footer-link-list">
                            <li><a className="text-decoration-none" href="/Inicio">Inicio</a></li>
                            <li><a className="text-decoration-none" href="/Sobre">Acerca de</a></li>
                            <li><a className="text-decoration-none" href="shop.html">Comprar</a></li>
                            <li><a className="text-decoration-none" href="contact.html">Contacto</a></li>
                        </ul>
                    </div>
                </div>

                <div className="row text-light mb-4">
                    <div className="col-12 mb-3">
                        <div className="w-100 my-3 border-top border-light"></div>
                    </div>
                    <div className="col-auto me-auto">
                        <ul className="list-inline text-left footer-icons">
                            <li className="list-inline-item border border-light rounded-circle text-center">
                                <a className="text-light text-decoration-none" target="_blank"
                                    href="https://www.facebook.com/profile.php?id=61567492461981">
                                    <i className="fab fa-facebook-f fa-lg fa-fw"></i>
                                </a>
                            </li>
                            <li className="list-inline-item border border-light rounded-circle text-center">
                                <a className="text-light text-decoration-none" target="_blank" href="https://github.com/EdgarGR123/CompoFinders.git">
                                    <i className="fab fa-github fa-lg fa-fw"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="w-100 bg-black py-3">
                <div className="container">
                    <div className="row pt-2">
                        <div className="col-12">
                            <p className="text-left text-light">
                                Copyright &copy; 2024 Compufinder
                                | Diseñado por <a rel="sponsored" href="https://github.com/EdgarGR123" target="_blank">Nahum Rosales</a> y <a rel="sponsored" href="https://github.com/jall17" target="_blank">Haily Hernández</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
