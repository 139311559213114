import '../estilos2/custom.css';
import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';

export default function ListaDeseos() {
    const [listaDeseos, setListaDeseos] = useState([]);

    useEffect(() => {
        const deseosGuardados = JSON.parse(localStorage.getItem("listaDeseos")) || [];
        setListaDeseos(deseosGuardados);
    }, []);

    const vaciarLista = () => {
        setListaDeseos([]);
        localStorage.removeItem("listaDeseos");
    };

    const eliminarProducto = (titulo) => {
        const nuevaLista = listaDeseos.filter(deseo => deseo.titulo !== titulo);
        setListaDeseos(nuevaLista);
        localStorage.setItem("listaDeseos", JSON.stringify(nuevaLista));
    };

    const cargarImagenBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };
            img.onerror = (error) => reject(error);
        });
    };

    const crear_pdf = async () => {
        const pdf = new jsPDF();
        pdf.setFontSize(16);
        pdf.text("Lista de Deseos", 10, 10); 
        let startY = 20;
        const maxWidth = 180;
    
        for (const [index, producto] of listaDeseos.entries()) {
            pdf.setFontSize(14);
            const titleLines = pdf.splitTextToSize(`${index + 1}. ${producto.titulo}`, maxWidth);
            pdf.text(titleLines, 10, startY);
            startY += titleLines.length * 10;
    
            pdf.setFontSize(12);
            const priceLines = pdf.splitTextToSize(`Precio: $${producto.precio}`, maxWidth);
            pdf.text(priceLines, 10, startY);
            startY += priceLines.length * 10;

            pdf.setFontSize(10);
            const urlLines = pdf.splitTextToSize(`Ver más: ${producto.url}`, maxWidth);
            pdf.text(urlLines, 10, startY);
            startY += urlLines.length * 10;
    
            let primeraImagen = '';
            try {
                const imagenesString = producto.imagenes
                    .replace(/&quot;/g, '"') 
                    .replace(/\\+/g, '') 
                    .replace(/(^"|"$)/g, ''); 
                const imagenesArray = JSON.parse(imagenesString);
                primeraImagen = imagenesArray[0]; 
            } catch (error) {
                console.error("Error al parsear ruta_imagen:", error);
            }
    
            try {

                const imgData = await cargarImagenBase64(primeraImagen); 
                pdf.addImage(imgData, 'PNG', 10, startY, 50, 50);
                startY += 60; 
            } catch (error) {
                console.error('Error al cargar la imagen:', error);
                startY += 10; 
            }
            startY += 10;
        }
    
        pdf.save("lista_deseos.pdf");
    };
    return (
        <section className="bg-light">
            <div className="container pb-5">
                <button className='botones_deseos' onClick={crear_pdf}>Guardar como PDF</button>
                <button className='botones_deseos' onClick={vaciarLista}>Vaciar Lista</button>
                <div>
                    <h1>Listado de Deseos</h1>
                    <div className='div-clases'>
                        {listaDeseos.length > 0 ? (
                            listaDeseos.map((producto, index) => {
                                // Extraer la primera imagen del array ruta_imagen
                                let primeraImagen = '';
                                try {
                                    const imagenesString = producto.imagenes
                                        .replace(/&quot;/g, '"') 
                                        .replace(/\\+/g, '') 
                                        .replace(/(^"|"$)/g, ''); 
                                    const imagenesArray = JSON.parse(imagenesString);
                                    primeraImagen = imagenesArray[0]; 
                                } catch (error) {
                                    console.error("Error al parsear ruta_imagen:", error);
                                }

                                return (
                                    <div className="cartas div-color" key={index}>
                                        <div className='image-div'>
                                            <img src={primeraImagen} alt={producto.titulo} /> 
                                        </div>
                                        <div className="contenido_producto">
                                            <span className="titulo">{producto.titulo}</span>
                                            <span className="precio">${producto.precio}</span> 
                                            <a href={producto.url} className="link" style={{backgroundColor:'transparent', textDecoration:'none'}}>Ver</a>
                                            <span className="origen">{producto.origen}</span>

                                            <br />
                                            <button className="ver_detalle" onClick={() => eliminarProducto(producto.titulo)}>
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No tienes productos en la lista de deseos.</p>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
