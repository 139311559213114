import '../estilos2/comparar.css';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Get_datos2 from '../API/datos2';
import { useSpeechSynthesis } from 'react-speech-kit';

export default function Comparar({ productos, vaciarComparar }) {
  const [productos_base_datos, setProductos_base_datos] = useState([]);
  const [productosComparar, setProductosComparar] = useState([]);
  const [grupo, setGrupo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [resultados, setResultados] = useState([]);
  const [error, setError] = useState('');

  const llamarAPIExterna = async (producto1, producto2) => {
    try {
        console.log('Enviando a la API:', { titulo_producto1: producto1, titulo_producto2: producto2 });
        const response = await fetch('https://improved-barnacle-q79pxqpjgvwpcgj7-5215.app.github.dev/ia_comparar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ titulo_producto1: producto1, titulo_producto2: producto2 }),
        });

        if (!response.ok) {
            throw new Error('Error en la llamada a la API externa');
        }

        const data = await response.json();
        console.log('Datos de la API externa:', data);
        if (data.message) {
            const rows = data.message.split('\n').map(row => {
                const cleanRow = row.replace(/'/g, '"').trim();
                if (cleanRow) {
                    try {
                        return JSON.parse(cleanRow);
                    } catch (e) {
                        console.error('Error al parsear fila:', cleanRow, e);
                        return [];
                    }
                }
                return [];
            }).filter(row => row.length > 0);

            setResultados(rows); 
        } else {
            setResultados([]); 
        }
        setError('');
    } catch (error) {
        console.error('Error al llamar a la API externa:', error);
        setError('Ocurrió un error al obtener los datos.');
    }
  };

  const agregarProducto = async (producto) => {
    const productoYaAgregado = productosComparar.some(
      (item) => item.id === producto.id 
    );

    if (productoYaAgregado) {
      alert('Este producto ya está en la lista de comparación.');
      return;
    }

    if (productosComparar.length === 0) {
      setGrupo(producto.nombre_componente);
      await peticion_base_datos(producto);
      setProductosComparar([producto]);
    } else if (producto.nombre_componente === grupo) {
      const lista_dos_elementos = [...productosComparar, producto];

      if (lista_dos_elementos.length === 2) {
        const titulos = lista_dos_elementos.map((p) => p.titulo); 
        setProductosComparar(lista_dos_elementos);
        await llamarAPIExterna(titulos[0], titulos[1]); 
        await peticion_base_datos(producto);
      } else if(lista_dos_elementos.length === 3) {
        alert('Solo puedes comparar productos de la misma subcategoría.');
      }

    } else {
      alert('Solo puedes comparar productos de la misma subcategoría.');
    }
  };

  const peticion_base_datos = async (producto) => {
    try {
      const datos = await Get_datos2(producto.titulo);
      if (datos) {
        setProductos_base_datos(datos);
      } else {
        console.error("No se recibieron datos.");
      }
    } catch (error) {
      console.error("Error al hacer la petición:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (question.trim() !== '') {
      setLoading(true);
      try {
        const response = await chat_api(question);
        setResponse(response.message);
      } catch (error) {
        console.error('Error al obtener la respuesta del chatbot:', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log('Por favor ingresa una pregunta.');
    }
  };

  const chat_api = async (question) => {
    try {
      const response = await fetch('https://improved-barnacle-q79pxqpjgvwpcgj7-5215.app.github.dev/chat_ia', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ titulo: question }),
      });

      if (!response.ok) {
        throw new Error('Error en la llamada a la API externa');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error al llamar a la API externa:', error);
    }
  };

  const [texto, setTexto] = useState('');
  const { speak, speaking, supported, voices, cancel } = useSpeechSynthesis();

  const voz_español = voices.find((voce) => voce.lang === 'es-MX');

  const Funcion_hablar = (texto) => {
    if (supported) {
      speak({ text: texto, voice: voz_español || null, rate: 1 });
    } else {
      alert('Tu navegador no soporta la síntesis de voz.');
    }
  };

  const generarTextoDeTabla = () => {
    if (resultados.length > 0) {
      const textoTabla = resultados.slice(1).map((row) => {
        return row.join(', ');
      }).join('. ');
      return textoTabla;
    }
    return '';
  };

  const parar_habla = () => {
    cancel();
  };

  return (
    <>
      <div className="container">
        <h1 className="text-center my-5">Comparación de Productos</h1>
        <h4 className="text-center">Solo se debe poner 2 productos y deben de ser de la misma categoria</h4>
        
        <div className="comparison-container">
          {productosComparar.length > 0 ? (
            productosComparar.map((producto, index) => {
              let primeraImagen = '';
              try {
                const imagenesString = producto.ruta_imagen
                  .replace(/&quot;/g, '"')
                  .replace(/\\+/g, '')
                  .replace(/(^"|"$)/g, '');
                const imagenesArray = JSON.parse(imagenesString);
                primeraImagen = imagenesArray[0];
              } catch (error) {
                console.error("Error al parsear ruta_imagen:", error);
              }
              return (
                <div className="product-box" key={index}>
                  <img src={primeraImagen} style={{height:'260px'}} alt={`Imagen de ${producto.titulo}`} />
                  <h2 className='hola'>{producto.titulo}</h2>
                  <ul className="features">
                    {(producto.funcionalidades || []).map((funcionalidad, idx) => (
                      <li key={idx}>{funcionalidad}</li>
                    ))}
                  </ul>
                </div>
              );
            })
          ) : (
            <p>No hay productos para comparar.</p>
          )}
        </div>

        <div className='comparaciones_result'>
          {resultados.length > 0 && (
            <>
              <table className="t-table">
                <thead>
                  <tr>
                    {resultados[0].map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {resultados.slice(1).map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, idx) => (
                        <td key={idx}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <div style={{display:'flex', borderRadius:'10px'}} >
                <a onClick={() => Funcion_hablar(generarTextoDeTabla())} style={{display:'flex' ,padding:'8px', border:'3px solid #b9fff9', borderRadius:'3px', cursor:'pointer'}}>
                  <i class="fa-solid fa-volume-low" style={{color:'b9fff9'}}></i>
                </a>
                <a style={{width:'5px'}}></a>
                <a onClick={() => parar_habla()} style={{display:'flex',padding:'8px', border:'3px solid #b9fff9', borderRadius:'3px', cursor:'pointer'}}>
                  <i className="fa-solid fa-stop" style={{color:'b9fff9'}}></i>
                </a>

              </div>
            </>
          )}
          
          {error && <p style={{ color: 'red' }}>{error}</p>} 
        </div>

        <div className="qa-section">
          <h2>Chatbot de Preguntas sobre los productos</h2>
          <div id="chatbotBox" className="chatbot-box">
            {
              loading ? <p>Cargando respuesta...</p> 
              : response && (
                <>
                  <p style={{padding:'5px', borderRadius:'10px'}}>{response}</p>  
                  <div style={{display:'flex', borderRadius:'10px'}} >
                    <a onClick={() => Funcion_hablar(response)} style={{display:'flex',padding:'8px', margin:'5px', border:'3px solid #b9fff9', borderRadius:'3px', cursor:'pointer'}}>
                      <i className="fa-solid fa-volume-low" style={{color:'b9fff9'}}></i>
                    </a>

                    <a onClick={() => parar_habla()} style={{display:'flex' , padding:'8px',  margin:'5px', border:'3px solid #b9fff9', borderRadius:'3px', cursor:'pointer'}}>
                      <i className="fa-solid fa-stop" style={{color:'b9fff9'}}></i>
                    </a>

                  </div>
                </>
              )
            }
          </div>

          <form id="questionForm" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                id="question"
                className="form-control"
                placeholder="Escribe tu pregunta aquí"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                style={{color:'#b9fff9', backgroundColor:'transparent'}}
              />
              <button type="submit" className="btn-cyan">Enviar</button>
            </div>
          </form>
        </div>

        <div className="container mt-5">
          <h3>Lista para comparar</h3>
          <button className="btn btn-danger" onClick={vaciarComparar}>Vaciar Lista</button>
          <div className="row">
            {productos.map((producto) => {
              let primeraImagen = '';
              try {
                const imagenesString = producto.ruta_imagen
                  .replace(/&quot;/g, '"')
                  .replace(/\\+/g, '')
                  .replace(/(^"|"$)/g, '');
                const imagenesArray = JSON.parse(imagenesString);
                primeraImagen = imagenesArray[0];
              } catch (error) {
                console.error("Error al parsear ruta_imagen:", error);
              }
              return (
                <div className="col-md-4 mb-4" key={producto.id}>
                  <div className="card">
                    <img src={primeraImagen} className="card-img-top" style={{height:'260px'}} alt={producto.titulo} />
                    <div className="card-body">
                      <h5 className="card-title" style={{color:'white'}}>{producto.titulo}</h5>
                      <button
                        className="btn btn-primary"
                        onClick={() => agregarProducto(producto)}
                      >
                        Agregar a Comparación
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
