import '../estilos2/custom.css';
import React, { useState, useEffect } from "react";
import Get_datos2 from "../API/datos2";
import { useNavigate } from "react-router-dom";

export default function ProductosLista({ productos = [], setElementos, categoria, toggleDeseo, toggleComparar }) {
    const navegador = useNavigate();
    const [listaDeseos, setListaDeseos] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const productosPorPagina = 9;

    const productosArray = Array.isArray(productos) ? productos : [];

    useEffect(() => {
        const deseosGuardados = JSON.parse(localStorage.getItem("listaDeseos")) || [];
        setListaDeseos(deseosGuardados);
    }, []);

    useEffect(() => {
        localStorage.setItem("listaDeseos", JSON.stringify(listaDeseos));
    }, [listaDeseos]);

    const clik_elemento = async (valor) => {
        const datos = await Get_datos2(valor);
        if (datos) { 
            setElementos(datos);
            navegador(`/Shop`);
        } else {
            console.error("No se recibieron datos.");
        }
    };

    const indiceUltimoProducto = paginaActual * productosPorPagina;
    const indicePrimerProducto = indiceUltimoProducto - productosPorPagina;
    const productosPaginados = productosArray.slice(indicePrimerProducto, indiceUltimoProducto);

    const totalPaginas = Math.ceil(productosArray.length / productosPorPagina);


    const cambiarPagina = (numeroPagina) => {
        setPaginaActual(numeroPagina);
    };

    const CompararClick = (producto) => {
        toggleComparar(producto); 
        navegador('/comparacion'); 
    };

    const toggleDeseoCLick = (producto) => {
        toggleDeseo(producto);
        navegador('/Lista'); 
    };

    return (
        <>
            <div className="col-lg-9">
                <h2>Productos {categoria ? `de ${categoria.replace("_", " ").toLowerCase()}` : "Aleatorios"}</h2>
                <div className="row">
                    {productosPaginados.length > 0 ? (
                        productosPaginados.map((producto, index) => {
                            let primeraImagen = '';
                            try {
                                const imagenesString = producto.ruta_imagen
                                    .replace(/&quot;/g, '"') 
                                    .replace(/\\+/g, '') 
                                    .replace(/(^"|"$)/g, ''); 
                                const imagenesArray = JSON.parse(imagenesString);
                                primeraImagen = imagenesArray[0]; 
                            } catch (error) {
                                console.error("Error al parsear ruta_imagen:", error);
                            }

                            return (
                                <div className="col-md-4" key={index}>
                                    <div className="card mb-4 product-wap rounded-0">
                                        <div className="card rounded-0">
                                            <img className="card-img rounded-0 img-fluid" style={{height:'260px' , objectFit:'cover'}} src={primeraImagen} alt={producto.titulo} onClick={() => clik_elemento(producto.titulo)} />
                                            <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <a className="btn btn-success text-white"  onClick={(e) => { e.preventDefault(); toggleDeseoCLick(producto, primeraImagen); }}>
                                                            <i className="fa-solid fa-cart-shopping" style={{backgroundColor:'transparent'}}></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="btn btn-success text-white mt-2" onClick={() => clik_elemento(producto.titulo)}>
                                                            <i className="far fa-eye"></i>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a className="btn btn-success text-white" onClick={(e) => { e.preventDefault(); CompararClick(producto)}}>
                                                            <i className="fas fa-balance-scale" style={{backgroundColor:'transparent'}}></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <a className="h3 text-decoration-none" style={{cursor:'pointer'}} onClick={() => clik_elemento(producto.titulo)}>{producto.titulo}</a>
                                            <p className="text-center mb-0" style={{ color: 'white' }}>${producto.precio_producto}</p>
                                            <span style={{ color: 'white' }}> {producto.origen.toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="col-12">
                            <p className="text-center">No hay productos disponibles.</p>
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="pagination-container overflow-auto">
                            <ul className="pagination pagination-lg justify-content-start">
                                {[...Array(totalPaginas)].map((_, index) => (
                                    <li key={index} className={`page-item ${paginaActual === index + 1 ? 'active' : ''}`}>
                                        <a 
                                            className="page-link rounded-0 mr-3 shadow-sm border-top-0 border-left-0 text-dark" 
                                            href="#" 
                                            onClick={(e) => { e.preventDefault(); cambiarPagina(index + 1); }}
                                        >
                                            {index + 1}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
