import '../estilos2/custom.css';
import React, { useState, useRef } from 'react';

import Mapas from '../components/mapas';

// Componente para un solo producto
function Producto({ elemento, toggleDeseo }) {
    const [descripcion, setDescripcion] = useState([]); 
    let imagenesArray = [];

    console.log(elemento.descripcion)

    try {
        const imagenesString = elemento.ruta_imagen
            .replace(/&quot;/g, '"')
            .replace(/\\+/g, '')
            .replace(/(^"|"$)/g, '');
        imagenesArray = JSON.parse(imagenesString);
    } catch (error) {
        console.error("Error al parsear ruta_imagen:", error);
    }

    const [imagenPrincipal, setImagenPrincipal] = useState(imagenesArray[0] || '');
    const [currentIndex, setCurrentIndex] = useState(0);
    const carruselRef = useRef(null);

    const scrollCarrusel = (direction) => {
        if (carruselRef.current) {
            const scrollAmount = 100; 
            carruselRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });

           
            setCurrentIndex((prevIndex) => {
                if (direction === 'left') {
                    return prevIndex > 0 ? prevIndex - 1 : 0;
                } else {
                    return prevIndex < imagenesArray.length - 1 ? prevIndex + 1 : imagenesArray.length - 1;
                }
            });
        }
    };

    const handleImagenClick = (imagen, index) => {
        setImagenPrincipal(imagen);
        setCurrentIndex(index);
    };

    const handleGenerarDescripcion = async (titulo) => {
        try {
            const response = await fetch('https://improved-barnacle-q79pxqpjgvwpcgj7-5215.app.github.dev///peticion_IA', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    titulo: titulo
                })
            });

            console.log(JSON.stringify(titulo))
    
            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                setDescripcion(data.message)
            } else {
                console.error('Error en la petición:', response.statusText);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };
    

    return (
        <section className="bg-light">
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-5 mt-5">
                        <div className="card mb-3">
                            <img className="card-img img-fluid" src={imagenPrincipal} alt="Imagen del producto" id="product-detail"/>
                        </div>

                        {imagenesArray.length > 1 && (
                            <div className="position-relative">
                                <button className="btn btn-light position-absolute start-0 top-50 translate-middle-y" onClick={() => scrollCarrusel('left')} style={{ zIndex: 1 }}>&#8249;</button>

                                <div className="imagenes_carrusel d-flex overflow-hidden" ref={carruselRef}>
                                    {imagenesArray.map((img, i) => (
                                        <img key={i} className="img-thumbnail me-2" src={img} alt={`Imagen ${i + 1}`} onClick={() => handleImagenClick(img, i)}
                                            style={{ cursor: 'pointer', width: '80px', height: '80px' }}
                                        />
                                    ))}
                                </div>

                                <button className="btn btn-light position-absolute end-0 top-50 translate-middle-y" onClick={() => scrollCarrusel('right')} style={{ zIndex: 1 }}>&#8250;</button>
                            </div>
                        )}
                    </div>
                    
                    <div className="col-lg-7 mt-5">
                        <div className="card">
                            <div className="card-body" style={{color:'white'}}>
                                <h1 className="h2">{elemento.titulo}</h1>
                                {elemento.descripcion.length > 100 && <p>{elemento.descripcion}</p>} 
                                <div>
                                    {
                                        (elemento.descripcion === 'El vendedor no incluyó una descripción del producto' || elemento.descripcion.length < 100)
                                        ? (<button className='boton_descripcion' onClick={() => handleGenerarDescripcion(elemento.titulo, setDescripcion)}>Generar Descripción</button>)
                                        : (<p>{descripcion}</p>)
                                    }
                                </div>
                                {descripcion && <p>{descripcion}</p>}
                                <p className="h3 py-2">$ {elemento.precio_producto}</p>
                                <span>{elemento.estado_producto}</span>
                                <p>{elemento.origen}</p>
                                <form action="#" method="GET">
                                    <input type="hidden" name="product-title" value={elemento.titulo} />

                                    <div className="row pb-3">
                                        <div className="col d-grid">
                                            <a className="btn btn-success btn-lg" href={elemento.url_producto} name="submit" value="buy">Ir a comprar</a>
                                        </div>
                                        <div className="col d-grid">
                                        <a className="btn btn-outline-success btn-lg" onClick={() => toggleDeseo(elemento)}>Añadir Lista Deseos</a>
                                        </div>
                                    </div>
                                </form>
                                <h1>Encuentra la ubicacion de las Tiendas de Componentes</h1>
                                <Mapas origen={elemento.origen} />
                            </div>

                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default function ProductoSolo_busqueda({ elementos = [], toggleDeseo }) {
    if (elementos.length === 0) {
        return (<div className="col-12"><p className="text-center">No hay productos disponibles.</p></div>);
    }
    return (
        <>
            {elementos.map((elemento, index) => (<Producto key={index} elemento={elemento} toggleDeseo={toggleDeseo}  />))}
        </>
    );
}
