
import '../estilos2/custom.css';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../estilos/leaflet-routing-machine.css';
import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';

const stores_json = [
    { origen: "kayfa", tienda: 'Kayfa_Plaza', location: [13.688117, -89.192856] },  
    { origen: "kayfa", tienda: 'Kayfa_Multicentro', location: [13.669913, -89.224902] },  
    { origen: "aeon", tienda: 'Aeon Las Cascadas', location: [13.694633, -89.260129] },  
    { origen: "aeon", tienda: 'Aeon Plaza Mundo', location: [13.685710, -89.238173] },  
    { origen: "zona_digital", tienda: 'Zona Digital', location: [13.687362, -89.206691] },  
    { origen: "zona_digital", tienda: 'Zona Digital Santa Elena', location: [13.686148, -89.253035] },  
    { origen: "intelmax", tienda: 'Intelmax COOPEFA', location: [13.693090, -89.221340] },  
    { origen: "omega", tienda: 'Omega Plaza Merliot', location: [13.684694, -89.259903] },  
    { origen: "omega", tienda: 'Omega Plaza Mundo', location: [13.685112, -89.234093] }  
];

const icon = L.icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41], 
    iconAnchor: [12, 41],
    shadowSize: [41, 41], 
});

const Mapas = () => {
    const [position, setPosition] = useState([13.688117, -89.192856]); 
    const [selectedStore, setSelectedStore] = useState("");
    const [mapInstance, setMapInstance] = useState(null); 
    const [routingControl, setRoutingControl] = useState(null); 

    useEffect(() => {
        const obtenerUbicacion = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setPosition([latitude, longitude]);
                        if (mapInstance) {
                            mapInstance.setView([latitude, longitude], 13);
                        }
                    },
                    () => {
                        console.warn("No se pudo obtener la ubicación del usuario. Usando ubicación predeterminada.");
                        setPosition([13.688117, -89.192856]);
                    }
                );
            } else {
                alert("La geolocalización no es soportada en este navegador.");
            }
        };

        obtenerUbicacion();
    }, [mapInstance]);

    const buscar_tienda = () => {
        const store = stores_json.find(s => s.tienda === selectedStore);
        if (store && position) {
            if (mapInstance) { 
                // Eliminar el control de rutas anterior si existe
                if (routingControl) {
                    routingControl.getPlan().setWaypoints([]); // Limpia los waypoints anteriores
                    mapInstance.removeControl(routingControl); // Elimina el control anterior
                }


// Crear un nuevo control de rutas
                const control = L.Routing.control({
                    waypoints: [
                        L.latLng(position[0], position[1]),
                        L.latLng(store.location[0], store.location[1])
                    ],
                    routeWhileDragging: true,
                    lineOptions: {
                        styles: [{ color: '#3388ff', opacity: 0.7, weight: 5 }]
                    }
                }).addTo(mapInstance);
                
                setRoutingControl(control); // Guardar el nuevo control de rutas en el estado
            }
        } else {
            alert("Por favor, selecciona una tienda y permite la ubicación.");
        }
    };

    const abrirEnWaze = () => {
        const store = stores_json.find(s => s.tienda === selectedStore);
        if (store) {
            const wazeUrl = `https://waze.com/ul?ll=${store.location[0]},${store.location[1]}&navigate=yes`;
            window.open(wazeUrl, '_blank');
        } else {
            alert("Por favor, selecciona una tienda.");
        }
    };

    const MapClickHandler = () => {
        const map = useMapEvents({
            click() {
                map.flyTo(position, map.getZoom());
            }
        });
        return null; 
    };

    return (
        <>
            <select id="storeSelect" className="form-select mt-3" onChange={e => setSelectedStore(e.target.value)}>
                <option value="">Selecciona una tienda</option>
                {stores_json.map(store => (
                    <option key={store.tienda} value={store.tienda}>{store.tienda}</option>
                ))}
            </select>
            <button id="getRoute" className="btn btn-primary mt-3" onClick={buscar_tienda}>Ubicacion</button>
            <button className="btn btn-primary mt-3" onClick={abrirEnWaze}>Abrir en Waze</button>
            <MapContainer
                center={position}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: '400px', width: '100%', border: '3px solid #55afb9', borderRadius: '5px' }}
                whenCreated={map => setMapInstance(map)}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {position && <Marker position={position} icon={icon}><Popup>Tu ubicación</Popup></Marker>}
                
                {selectedStore && (
                    stores_json.map(store => 
                        store.tienda === selectedStore ? (
                            <Marker key={store.tienda} position={store.location} icon={icon}>
                                <Popup>{store.tienda}</Popup>
                            </Marker>
                        ) : null
                    )
                )}
                <MapClickHandler />
            </MapContainer>
        </>
    );
};

export default Mapas;
