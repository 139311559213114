import '../estilos2/custom.css';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSpeechSynthesis } from 'react-speech-kit'; 
import Mapas from './mapas';

export default function ProductoSolo({ elementos = [], toggleDeseo }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [descripcion, setDescripcion] = useState('');
    const carruselRef = useRef(null);

    const scrollCarrusel = (direction, imagenesArray) => {
        if (direction === 'left') {
            setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imagenesArray.length - 1));
        } else if (direction === 'right') {
            setCurrentIndex((prevIndex) => (prevIndex < imagenesArray.length - 1 ? prevIndex + 1 : 0));
        }
    };

    const handleGenerarDescripcion = async (titulo) => {
        try {
            const response = await fetch('https://improved-barnacle-q79pxqpjgvwpcgj7-5215.app.github.dev///peticion_IA', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ titulo: titulo })
            });

            if (response.ok) {
                const data = await response.json();
                setDescripcion(data.message); 
            } else {
                console.error('Error en la petición:', response.statusText);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };
    
    const { speak, supported, voices } = useSpeechSynthesis();
    const voz_español = voices.find((voce) => voce.lang === 'es-MX');

    const Funcion_hablar = (texto) => {
        if (supported) {
            speak({ text: texto, voice: voz_español || null, rate: 1 });
        } else {
            alert('Tu navegador no soporta la síntesis de voz.');
        }
    };

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
                <script src="https://unpkg.com/leaflet/dist/leaflet.js"></script>
                <script src="https://unpkg.com/leaflet-routing-machine/dist/leaflet-routing-machine.js"></script>
            </Helmet>

            {elementos.length > 0 ? (
                elementos.map((elemento, index) => {
                    let imagenesArray = [];
                    try {
                        const imagenesString = elemento.ruta_imagen
                            .replace(/&quot;/g, '"')
                            .replace(/\\+/g, '')
                            .replace(/(^"|"$)/g, '');
                        imagenesArray = JSON.parse(imagenesString);
                    } catch (error) {
                        console.error("Error al parsear ruta_imagen:", error);
                    }

                    return (
                        <section className="bg-light" key={index}>
                            <div className="container pb-5">
                                <div className="row">
                                    <div className="col-lg-5 mt-5">
                                        <div className="card mb-3">
                                            <img
                                                className="card-img img-fluid"
                                                src={imagenesArray[currentIndex]}
                                                alt="Imagen del producto"
                                                id="product-detail"
                                            />
                                        </div>

                                        <div className="position-relative">
                                            <button
                                                className="btn btn-light position-absolute start-0 top-50 translate-middle-y"
                                                onClick={() => scrollCarrusel('left', imagenesArray)}
                                                style={{ zIndex: 1 }}>
                                                &#8249;
                                            </button>

                                            <div className="imagenes_carrusel d-flex overflow-hidden" ref={carruselRef}>
                                                {imagenesArray.map((img, i) => (
                                                    <img
                                                        key={i}
                                                        className={`img-thumbnail me-2 ${currentIndex === i ? 'active' : ''}`}
                                                        src={img}
                                                        alt={`Imagen ${i + 1}`}
                                                        onClick={() => setCurrentIndex(i)}
                                                        style={{ cursor: 'pointer', width: '80px', height: '80px' }}
                                                    />
                                                ))}
                                            </div>

                                            <button
                                                className="btn btn-light position-absolute end-0 top-50 translate-middle-y"
                                                onClick={() => scrollCarrusel('right', imagenesArray)}
                                                style={{ zIndex: 1 }}
                                            >
                                                &#8250;
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-lg-7 mt-5">
                                        <div className="card">
                                            <div className="card-body" style={{ color: 'white' }}>
                                                <h1 className="h2">{elemento.titulo}</h1>
                                                {elemento.descripcion.length > 100 && <p>{elemento.descripcion}</p>} 

                                                <div>
                                                    { 
                                                        (elemento.descripcion === 'El vendedor no incluyó una descripción del producto' || elemento.descripcion.length < 100)
                                                        ? (
                                                            <button className='boton_descripcion' onClick={() => handleGenerarDescripcion(elemento.titulo)}>
                                                                Generar Descripción
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <p>{descripcion}</p>
                                                                {descripcion && ( 
                                                                    <a onClick={() => Funcion_hablar(descripcion)} style={{ padding: '8px', border: '3px solid #b9fff9', borderRadius: '3px', cursor: 'pointer' }}>
                                                                        <i className="fa-solid fa-volume-high" style={{ color: '#b9fff9' }}></i>
                                                                        Leer descripción
                                                                    </a>
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <p className="h3 py-2">$ {elemento.precio_producto}</p>
                                                <span>{elemento.estado_producto}</span>
                                                <p>{elemento.origen}</p>
                                                <form action="#" method="GET">
                                                    <input type="hidden" name="product-title" value={elemento.titulo} />
                                                    <div className="row pb-3">
                                                        <div className="col d-grid">
                                                            <a className="btn btn-success btn-lg" href={elemento.url_producto} name="submit" value="buy">
                                                                Ir a comprar
                                                            </a>
                                                        </div>
                                                        <div className="col d-grid">
                                                            <a className="btn btn-outline-success btn-lg" onClick={() => toggleDeseo(elemento)}>
                                                                Añadir Lista Deseos
                                                            </a>
                                                        </div>
                                                    </div>
                                                </form>
                                                <h1>Encuentra la ubicacion de las Tiendas de Componentes</h1>
                                                <Mapas origen={elemento.origen} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    );
                })
            ) : (
                <h2>No se encontraron productos</h2>
            )}
        </>
    );
}
