import React, { useState } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';

function TextToSpeech() {
  const [text, setText] = useState('');
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();

  const spanishVoice = voices.find((voice) => voice.lang === 'es-MX');

  const handleSpeak = () => {
    if (supported) {
      speak({ text, voices: spanishVoice || null, rate: 1 });
    } else {
      alert('Tu navegador no soporta la síntesis de voz.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2>Texto a Voz</h2>
      <textarea
        rows="5"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Escribe el texto que deseas escuchar..."
        style={{ width: '100%', padding: '10px' }}
      />
      <div>
        <button onClick={handleSpeak} disabled={speaking}>
          Escuchar
        </button>
        <button onClick={cancel} disabled={!speaking}>
          Detener
        </button>
      </div>
    </div>
  );
}

export default TextToSpeech;
